import * as React from "react"
import { graphql } from 'gatsby'
import App from '../components/App'
import '../styles/global.css'

import Nav from '../components/Nav'
import HeroSection from '../components/HeroSection'
import IntroSection from '../components/IntroSection'
import PhotoSection from '../components/PhotoSection'
import ContactSection from '../components/ContactSection'
import Footer from '../components/Footer'
import Tracking from '../components/Tracking'

// import pageQuery from '../queries/pageQuery'
// export const query = pageQuery
export const query = graphql`
query IndexQuery($pathname: String) {
  allPagesYaml(filter: {path: {eq: $pathname}}) {
    edges {
      node {
        sections {
          id
          type
          heading
          content
          subject
          ...PhotoSectionFragment
          ...HeroSectionFragment
        }
      }
    }
  }
}
`

const components = {
  Nav,
  HeroSection,
  IntroSection,
  PhotoSection,
  ContactSection,
  Footer,
  Tracking,
}

export default function IndexPage(props) {
  const sections = props.data.allPagesYaml.edges[0].node.sections
  return (
    <App
      sections={sections}
      components={components}
    />    
  )
}
