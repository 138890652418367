import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import Button from '../Button'

export const query = graphql`
  fragment PhotoSectionFragment on PagesYamlSections {
    isReversed
    fullWidth
    contentPre
    background
    photos {
      alt
      src {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      isOffset
    }
    cta {
      text
      href
      to
    }
  }
`

function PhotoSection(props) {
  const {
    heading,
    content,
    photos,
    cta,
    extraClasses,
    isReversed,
    fullWidth,
    contentPre,
    background
  } = props
  return (
    <div className={`PhotoSection ${extraClasses}`} style={{ background }}>
      <div className="center">
        <div
          className={`m-auto text-center ${!fullWidth && 'md:text-left'} flex flex-col ${!fullWidth && 'md:flex-row items-center'} pt-10 ${isReversed ? 'md:flex-row-reverse' : ''}`}
          style={{ maxWidth: '110rem' }}
        >
          <div className={`inline-block ${!fullWidth && 'md:w-1/2'} md:align-top ${!fullWidth && 'md:my-20'}`}>
            {photos && photos.map((photo, i) => {
              const width = (photos.length === 1) ? '' : 'w-1/2'
              // console.log(photo.src)
              const image = getImage(photo.src)
              return (
                <div key={i} className={`inline-block ${width} align-top ${photo.isOffset && 'mt-12'} ${photos.length > 1 && (i === 1 ? 'pl-1' : 'pr-1')}`}>
                  <GatsbyImage
                    image={image}
                    alt={photo.alt || ""}
                    imgStyle={{ display: 'inline-block' }}
                  />
                </div>
              )
            })}
          </div>
          <div className={`inline-block max-w-md p-20 pb-24 ml-auto mr-auto ${!fullWidth && 'md:w-1/2 mb-4'}`}>
            <h2 id={heading} className="text-4xl font-serif leading-none">{heading}</h2>
            <p className={`lh-copy my-8 ${contentPre ? 'whitespace-pre-wrap' : ''}`}>{content}</p>
            {cta && (
              <Button
                href={cta.href}
                to={cta.to}
              >
                {cta.text}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

PhotoSection.defaultProps = {
  extraClasses: '',
  // heading: 'PhotoSection',
  // content: 'Lots of copy.',
  photos: [],
  additionalContent: []
}

export default PhotoSection
