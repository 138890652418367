import React from 'react'
import Button from '../Button'
// import './styles.css'

export const headingStyles = 'text-4xl font-serif leading-none mb-8 text-center'
export const inputStyles = 'border focus:outline-none focus:ring rounded border-gray-300 py-1 px-2 height-36'

function Contact(props) {
  const { extraClasses, heading, subject, content, fields, id } = props

  return (
    <div id={id} className={`Contact ${extraClasses}`}>
      <div className="max-w-lg m-auto py-4 px-3">
        <h2 id={heading} className={headingStyles}>{heading}</h2>
        <p>{content}</p>
        <form className="my-3" action="https://formspree.io/info@deerhillvineyards.com" method="POST">
          {fields.map((field, i) => {
            const isTextarea = field.type === 'textarea'
            return (
              <label className={`dib ${isTextarea ? 'w-full pt-3' : 'border-box w-full pt2 pr3-ns'}`} key={i}>
                <div className="ttc pb2">{field.name}</div>
                <div className="mb-3">
                  {isTextarea
                    ? (
                      <textarea
                        className="border focus:outline-none focus:ring rounded border-gray-300 py-1 px-2 w-full"
                        rows="7"
                        {...field}
                      />
                    ) : (
                      <input
                        className={`${inputStyles} w-full`}
                        {...field}
                      />
                    )}
                  </div>
              </label>
            )
          })}
          <input type="text" name="_gotcha" style={{ display: 'none' }} />
          <input type="hidden" name="_subject" value={subject} />
          <input type="hidden" name="_cc" value="pogo89+dh@gmail.com" />
          <Button className="my-2" type="submit">Send</Button>
        </form>
      </div>
    </div>
  )
}

Contact.defaultProps = {
  extraClasses: '',
  heading: 'Contact',
  fields: [
    {
      name: 'name',
      type: 'text',
      required: true,
    },
    {
      name: 'email',
      type: 'text',
      required: true,
    },
    {
      name: 'subject',
      type: 'text',
      required: true,
    },
    {
      name: 'body',
      type: 'textarea',
      required: true,
    }
  ],
  subject: 'New submission!'
}

export default Contact
