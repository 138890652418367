import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'

export const query = graphql`
  fragment HeroSectionFragment on PagesYamlSections {
    heroPhoto {
      alt
      src {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  }
`

function Hero({ heroPhoto }) {
  const image = getImage(heroPhoto.src)
  return (
    <div className={`Hero w-full overflow-hidden text-center`}>
      <GatsbyImage
        image={image}
        alt={heroPhoto.alt}
        style={{ maxWidth: 1800 }}
      />
      {/* <StaticImage
        loading="eager"
        placeholder="blurred"
        className="m-auto relative max-w-none max-w-8xl md:max-w-full"
        alt="arial view of house and vineyard"
        src="../../images/bg-pics-2.png"
      /> */}
    </div>
  )
}

Hero.defaultProps = {
}

export default Hero
