import React from 'react'
// import './styles.css'
import Link from '../Link'
import Subscribe from '../Subscribe'
import { StaticImage } from 'gatsby-plugin-image'
import facebookIcon from './facebook.svg'
import instagramIcon from './instagram.svg'
import yelpIcon from './yelp.svg'

// const thickBorder = 'border-t-4 border-solid border-gray-100'

function Footer(props) {
  const { extraClasses, copyrightName } = props
  return (
    <div id="Footer" className={`Footer ${extraClasses}`}>
      <div className="bg-gray-100 py-6">
        <Subscribe />
      </div>
      <div className="max-w-4xl mt-16 m-auto flex flex-col justify-items-center md:flex-row">
        <div className="flex-auto md:order-2 p-2 my-4 text-center">
          <StaticImage
            src="../../images/deer-hill-vineyards-logo-scroll-sm.png"
            alt="Deer Hill Vineyards logo"
            placeholder="blurred"
            layout="fixed"
            width={270}
            style={{ margin: 'auto' }}
            // height={200}
          />
          <div className="text-center my-6 opacity-60">
            <Link
              className="inline-block p-1 mx-1"
              component="a"
              target="_blank"
              noBorder
              rel="noopener noreferrer"
              href="https://www.facebook.com/DeerHillVineyard/">
              <img height="32" width="32" src={facebookIcon} alt="facebook" />
            </Link>
            <Link
              className="inline-block p-1 mx-1"
              component="a"
              target="_blank"
              noBorder
              rel="noopener noreferrer"
              href="https://www.instagram.com/deerhillvineyards/">
              <img height="32" width="32" src={instagramIcon} alt="instagram" />
            </Link>
            <Link
              className="inline-block p-1 mx-1"
              component="a"
              target="_blank"
              noBorder
              rel="noopener noreferrer"
              href="https://www.yelp.com/biz/deer-hill-vineyards-lafayette-6">
              <img height="32" width="32" src={yelpIcon} alt="yelp" />
            </Link>
          </div>
        </div>

        <div className="flex-1 md:order-1 p-2 my-4">
          <h3 className="text-2xl text-indigo-800 uppercase mb-2">Contact info</h3>
          <ul>
            <li className="mb-3">
              <Link component="a" href="https://www.google.com/maps/place/Deer+Hill+Vineyards/@37.8941506,-122.1284301,15z/data=!4m5!3m4!1s0x0:0x94dd081d6b823bf9!8m2!3d37.8940243!4d-122.1284452">
                Deer Hill Vineyards<br />
                6 Lois Lane<br />
                Lafayette, CA 94549
              </Link>
            </li>
            <li className="mb-3">
              <Link component="a" href="mailto:info@deerhillvineyards.com">
                info@deerhillvineyards.com
              </Link>
            </li>
            <li className="mb-3">
              <Link component="a" href="tel:1-925-200-7676">
                925-200-7676
              </Link>
            </li>
          </ul>
        </div>

        <div className="flex-1 md:order-3 p-2 my-4">
          <h3 className="text-2xl text-indigo-800 uppercase mb-2">Links</h3>
          <ul>
            <li className="mb-3">
              <Link to="/">
                Home
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/events/">
                Events
              </Link>
            </li>
            <li className="mb-3">
              <Link to="/menu/">
                Menu
              </Link>
            </li>
            <li className="mb-3">
              <Link component="a" href="https://store.deerhillvineyard.com/">
                Store
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-gray-100 text-sm text-center p-4">
        <span>{`© ${new Date().getUTCFullYear()} `}</span>
        <span>{copyrightName}</span>
      </div>
    </div>
  )
}

Footer.defaultProps = {
  extraClasses: '',
  copyrightName: 'Deer Hill Vineyards'
}

export default Footer
