import React from 'react'
import './styles.css'
import { inputStyles } from '../ContactSection'
import { buttonStyles } from '../Button'

function EmailSignup () {
  return (
    <div id="mc_embed_signup">
      <form action="https://deerhillvineyards.us19.list-manage.com/subscribe/post?u=36773c192fc9e9582d154e0fc&amp;id=e6b6bc3e22" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
        className="" target="_blank" noValidate>
        <div id="mc_embed_signup_scroll">
          <label htmlFor="mce-EMAIL" className="">Subscribe to our mailing list</label>
          <input type="email" defaultValue="" name="EMAIL" className={`email mx-4 my-3 ${inputStyles}`} id="mce-EMAIL" placeholder="email address" required />
          <input type="submit" defaultValue="Subscribe" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className={buttonStyles} />
          <div style={{ position: 'absolute', left: -5000 }} aria-hidden="true"><input type="text" name="b_36773c192fc9e9582d154e0fc_e6b6bc3e22" tabIndex="-1" defaultValue="" /></div>
        </div>
      </form>
    </div>
  )
}

function Subscribe(props) {
  const { extraClasses } = props
  return (
    <div className={`Subscribe ${extraClasses}`}>
      <div className="text-center">
        <EmailSignup />
      </div>
    </div>
  )
}

Subscribe.defaultProps = {
  extraClasses: '',
  // heading: 'Subscribe',
  // body: 'Lots of copy.',
  // additionalContent: []
}

export default Subscribe
