import React from 'react'
// import './styles.css'

const headingStyles = 'text-4xl font-serif leading-none mb-8'

function IntroSection(props) {
  const { heading, content, extraClasses } = props
  return (
    <div className={`IntroSection ${extraClasses}`}>
      <div className="">
        <div className="max-w-md ml-auto mr-auto text-center p-20">
          <h2 id={heading} className={headingStyles}>{heading}</h2>
          <p className="leading-6">{content}</p>
        </div>
      </div>
    </div>
  )
}

IntroSection.defaultProps = {
  extraClasses: '',
  // heading: 'IntroSection',
  // content: 'Lots of copy.',
  additionalContent: []
}

export default IntroSection
